import { render, staticRenderFns } from "./tort.vue?vue&type=template&id=ebfe0240&scoped=true&"
import script from "./tort.vue?vue&type=script&lang=js&"
export * from "./tort.vue?vue&type=script&lang=js&"
import style0 from "./tort.vue?vue&type=style&index=0&id=ebfe0240&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebfe0240",
  null
  
)

export default component.exports