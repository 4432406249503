<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <!-- 版权侵权告知书 -->
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/aiNav')">返回</p>
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“AI助手”-版权侵权告知函" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">侵权方：</p>
                            <div class="upload_box">
                                <el-input v-model="form.defendant_name" placeholder="请输入侵权公司名称或个人姓名"></el-input>

                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">告知人：</p>
                            <div class="upload_box">
                                <el-input v-model="form.plaintiff_name" placeholder="请输入告知人姓名"></el-input>
                            </div>
                        </div>
                        <!-- <div class="form_ct flex">
                            <p class="upLoad_text">委托人性别：</p>
                            <div class="upload_box flex">
                                <el-radio v-model="form.plaintiff_sex" label="男">男</el-radio>
                                <el-radio v-model="form.plaintiff_sex" label="女">女</el-radio>

                            </div>
                        </div> -->
                        <div class="form_ct flex">
                            <p class="upLoad_text">我方联系人：</p>
                            <div class="upload_box">
                                <el-input v-model="form.plaintiff_id" placeholder="请输入我方联系人"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">我方联系电话：</p>
                            <div class="upload_box">
                                <el-input v-model="form.plaintiff_mobile" placeholder="请输入我方联系电话"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">我方联系邮箱：</p>
                            <div class="upload_box">
                                <el-input v-model="form.plaintiff_email" placeholder="请输入我方联系邮箱"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">我方要求：</p>
                            <div class="upload_box">
                                <el-input :autosize="{ minRows: 4, maxRows: 4 }" class="upload_textarea" type="textarea"
                                    v-model="form.plaintiff_appeal" placeholder="具体的要求事项，如停止侵权行为、赔偿损失等"></el-input>
                            </div>
                        </div>
                        <button class="create_but" @click="FileData">生成内容</button>
                        <ai_text_content :contentData="contentData" />
                        <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                            @close="aiNumShow = false" @postData="postData" />
                        <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                    </div>
                    <aiNav />

                </div>

            </div>


        </div>
        <footerBot />
    </div>
</template>

<script>
import ai_text_content from '@/components/ai_text_content'
import aiNav from '@/components/aiNav'
import ai_title from '@/components/ai_title'
export default {
    mounted() {
        this.getUserInfo()
    },
    components: {
        aiNav,
        ai_text_content,
        ai_title
    },
    data() {
        return {
            form: {
                defendant_name: '', //侵权方
                plaintiff_name: '', //告知人
                // plaintiff_sex: '男', // 委托人性别
                plaintiff_id: '', // 我方联系人
                plaintiff_mobile: '', // 我方联系电话
                plaintiff_email: '', // 我方联系邮箱
                plaintiff_appeal: '', // 我方诉求
            },
            contentData: {

            }, //内容
            fullscreenLoading: false,
            aiNumShow: false,
            aiPoupShow: false,
        }
    },
    methods: {
        getImg(url) {
            this.contentData.origin_url = url
            console.log('contentData', this.contentData);
        },
        // 获取用户信息
        getUserInfo() {
            this.curlGet('/api/user/info').then((res) => {
                if (res.data.code) {
                    this.$user_info = res.data.data
                    this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                }
            })
        },
        FileData() {
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }
        },
        postData() {
            this.aiNumShow = false
            this.fullscreenLoading = true
            let data = this.form
            data.mobile = this.$user_info.mobile
            this.curlPost('file/copyright_create', data).then(res => {
                console.log(res);
                if (res.data.code) {
                    this.contentData = res.data.data

                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()
                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
                this.fullscreenLoading = false
            })
        }

    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>